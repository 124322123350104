.chatList__msg--video[data-v-26577b20] {
  width: 100%;
  height: calc(100% - 60px);
  /* margin: 10px 0; */
  overflow: hidden;
}
.btn-container[data-v-26577b20] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: absolute;
  top: 5px;
  right: 12px;
}
.btn-container[data-v-26577b20] .el-button:focus,
.btn-container[data-v-26577b20] .el-button:hover {
  color: #303133;
  border-color: #f6f7fa;
  background-color: #f6f7fa;
}
.user-dialog[data-v-26577b20] .el-dialog__body {
  padding: 0 20px !important;
}
.common_tree_handle[data-v-26577b20] {
  border-right: none !important;
  border-left: 1px solid #cccccc;
}
.assessment-content[data-v-26577b20] {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #cccccc;
}
.assessment-content .assessment-avatar[data-v-26577b20] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 30px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.assessment-content .assessment-avatar span[data-v-26577b20] {
  padding-top: 10px;
  text-align: center;
}
.assessment-content .assessment-item .assessment-rate .el-rate[data-v-26577b20] {
  padding-right: 30px;
}
.right-container[data-v-26577b20] {
  padding-left: 10px;
  position: relative;
}
.rightBtn[data-v-26577b20] {
  width: 48px;
  height: 96px;
  position: absolute;
  left: -20px;
  top: calc(50% - 48px);
  z-index: 99;
}
.smallContainer[data-v-26577b20] {
  height: 85vh;
  overflow: auto;
}
[data-v-26577b20] .el-tabs__header {
  margin: 0;
}
[data-v-26577b20] .el-tabs__item {
  color: #d2d3d6;
}
[data-v-26577b20] .el-tabs__item.is-active {
  color: #303133;
}
[data-v-26577b20] .el-tabs__active-bar {
  background-color: #303133;
}
[data-v-26577b20] .el-tabs__item:hover {
  color: #303133;
}
.content-knowledge[data-v-26577b20] {
  width: 256px;
  height: calc(100vh - 86px);
  padding: 12px;
  background: #ffffff;
}
.content-knowledge .knowledge-item[data-v-26577b20] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #f6f7fa;
  padding: 8px 10px;
  border-radius: 2px;
  margin-bottom: 6px;
}
.content-knowledge .knowledge-item span[data-v-26577b20] {
  color: #606266;
  font-size: 14px;
  font-weight: 400;
}
.content-knowledge .knowledge-item span i[data-v-26577b20] {
  font-size: 16px;
  margin-right: 10px;
}
.content-knowledge .knowledge-text[data-v-26577b20] {
  color: #606266;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
}
.browseHead[data-v-26577b20] .head-layout {
  padding: 15px 0 12px 12px;
}